import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "@popperjs/core/dist/umd/popper.min.js";

import "/src/style/main.scss";

import React from "react";

export const onRenderBody = ({ setHeadComponents }) => {
    setHeadComponents([
        <script
            key="gtag-consent"
            dangerouslySetInnerHTML={{
                __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag() {
              dataLayer.push(arguments);
          }
          gtag("consent", "default", {
              ad_personalization: "default",
              ad_storage: "default",
              ad_user_data: "default",
              analytics_storage: "default",
              functionality_storage: "default",
              personalization_storage: "default",
              security_storage: "granted",
              wait_for_update: 500,
          });
          gtag("set", "ads_data_redaction", true);
          gtag("set", "url_passthrough", false);
        `,
            }}
            data-cookieconsent="ignore"
        />,
    ]);
};
